// Typography
//
// Headings, body text, lists, and other misc typographic elements.

h1,
h2,
h3,
h4,
h5,
h6,
.site-title {
  color: $heading-color;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: $heading-spacing;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.5rem;
}

h4,
h5,
h6 {
  font-size: 1rem;
  margin-top: 1rem;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

strong {
  color: #303030;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  margin-top: 0;
  padding-left: 0;
}
li {
  margin-left: 40px;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
}

hr {
  border: 0;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #eee;
  margin: 1.5rem 0;
  position: relative;
}

abbr {
  color: #555;
  font-size: 85%;
  font-weight: bold;
  text-transform: uppercase;

  &[title] {
    border-bottom: 1px dotted $border-color;
    cursor: help;
  }
}

blockquote {
  border-left: 0.25rem solid $border-color;
  color: #7a7a7a;
  margin: 0.8rem 0;
  padding: 0.5rem 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-left: 1.25rem;
    padding-right: 5rem;
  }
}

// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^='#fn:'],
// Back to footnote link
a[href^='#fnref:'] {
  display: inline-block;
  font-weight: bold;
  margin-left: 0.1rem;
}

// List of footnotes
.footnotes {
  font-size: 85%;
  margin-top: 2rem;
}

// Custom type
//
// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: initial;
}

// SVG Icons
a svg {
  fill: $link-color;
  pointer-events: none;
}


#ads-link svg {
  height: 2.3rem;
  width: auto;
  margin-top: -0.16rem;
  margin-right: -0.16rem;
}

.icon {
  vertical-align: middle;
}

.navbar {
  display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.thumbnail img{
  margin:auto;
  @media (min-width: $large-breakpoint) {
      width: 80%
  }
}